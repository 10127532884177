import React, { useState, useEffect } from 'react';
import { Container, Segment, Loader } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import NavItem from './NavItem'; // Ensure NavItem is correctly imported from your components

const About = () => {
    // Initialize loading state to true to show the loader initially
    const [isLoading, setIsLoading] = useState(true);

    // useEffect hook to simulate loading data when component mounts
    useEffect(() => {
        // Simulate fetching data with a timeout
        // After 2 seconds, set isLoading to false
        const timer = setTimeout(() => setIsLoading(false), 2000);

        // Cleanup function to clear the timer if the component unmounts before the timeout completes
        return () => clearTimeout(timer);
    }, []); // Empty dependency array means this runs once on mount

    return (
        <div  style={{ backgroundColor: '#24292e', minHeight: '100vh', fontFamily: "'JetBrains Mono', monospace", color: '#ffffff' }}>
            <NavItem />

            {isLoading ? (
                // Show the loader if isLoading is true
                <Loader active inline="centered" style={{ marginTop: '2em' }}>Loading...</Loader>
            ) : (
                // Once loading is complete, display the content
                <Container text style={{ marginTop: '2em' }}>
                    <Segment inverted >
                        <h2>About Me</h2>
                        <p>Elvis Otieno, based in Nairobi, Kenya, encapsulates a dynamic career spanning over 4 years, with a focus on cybersecurity, data architecture, and software development. My professional journey is fueled by an unwavering passion for Information and Communication Technology (ICT) and a commitment to innovation.</p>
                        <p>In my role as a Data Architect/Contractor, I've honed my expertise in data modeling, business data analysis, and the design of both OLTP and OLAP systems. My work has been pivotal in optimizing data architecture, underscoring my proficiency in transforming complex data into actionable insights.</p>
                        <p>As a Cybersecurity Advocate, I've led initiatives aimed at bolstering cybersecurity measures and awareness, directly contributing to the safeguarding of digital assets in an increasingly vulnerable cyber landscape.</p>
                        <p>My dedication to mentorship and development of emerging tech talent is evident through my involvement in organizing hackathons, seminars, and hands-on workshops. These platforms not only foster skill development but also inspire the next generation of technology leaders towards meaningful, real-world problem-solving.</p>
                        <p>As a testament to my commitment to fostering a more secure and inclusive digital world, I am an active member of the global Internet Society. My endeavors reflect a deep-seated belief in the power of collaborative innovation and the importance of knowledge sharing in the ever-evolving field of ICT.</p>
                        <p>I welcome the opportunity to collaborate, share insights, and contribute to projects that drive forward the cybersecurity and tech innovation landscape. Let’s connect and explore how we can make a difference together.</p>
                        <p>Email: hello@elvisotieno.com</p>
                        <p>LinkedIn: <a href="https://www.linkedin.com/in/elvis-otieno-2018/" style={{ color: 'lightblue' }}>https://www.linkedin.com/in/elvis-otieno-2018/</a></p>
                    </Segment>
                </Container>
            )}
        </div>
    );
};

export default About;

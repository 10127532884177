import React, { useState } from 'react'; // Corrected import
import { Container, Segment, Input, Button, Card, Loader } from 'semantic-ui-react';
import { FaSearch } from 'react-icons/fa';
import Typing from 'react-typing-effect';
import nlp from 'compromise';
import 'semantic-ui-css/semantic.min.css';
import './style.css';
const cvData = {
  profile: "Elvis Otieno, based in Nairobi, Kenya. I am an experienced Data Architect / Contractor with a rich background in various fields including cyber security and software development. I am passionate about using technology to solve real-world problems and actively contribute to the cyber security community.",
  skills: "I am proficient in JavaScript (including frameworks like React and React Native), mobile application development, Apache Kafka, and more. I have also earned certifications in AWS services and Snowflake data warehousing.",
  experience: "Currently, I work as a Data Architect / Contractor at DataSeal Kenya, Nairobi (since November 2021). My role involves data modeling, business data analysis, and designing OLTP and OLAP systems.",
  education: "I hold an AWS Cloud Practitioner Certification and am a Certified Information Security Manager (CISM). My educational background has provided me with a strong foundation in cloud computing concepts and information security management.",
  projects: "I have developed the Kecirt mobile app for the Communication Authority of Kenya and the Truewater mobile app, showcasing my skills in full-stack development and project management.",
  cyberSecurity: "As a cyber security expert and esports enthusiast, I've been a key speaker at Hexcon2022 and regularly share insights on securing systems and protecting against cyber threats through various platforms.",

};

const SearchResultCard = ({ title, description, link }) => (
    <Card
      href={link}
      header={title}
      description={description}
      onClick={() => window.open(link, "_blank")}
    />
  );
  
 



const executeCommand = (command) => {
    // You can implement your command execution logic here
    // For simplicity, let's just echo the command
    return `$ ${command}`;
  };
  const mappings = [
    
        
    {
      keywords: ['Expirience','Good at'],
      key: 'Expirience',
      response: "* Website Develpment, Mobile Application Developement, Cyber Security, Data Modeling and Database design"
    },
    {
      keywords: ['mobile application development','mobile development','mobile application development','mobile development','Application','Software'],
      key: 'Mobile Developement',
      response: "📱 I have developed the Kecirt mobile app for the Communication Authority of Kenya and the Truewater mobile app, showcasing my skills in full-stack development and project management.NB > the apps might not be up to date as i am not activly supporting them "
    },
    {
      keywords: ['Name','hello','hi'],
      key: 'Name',
      response: "👋 I'm Elvis Otieno, https://www.linkedin.com/in/elvis-otieno-2018/"
    },
    {
      keywords: ['Education'],
      key: 'education',
      response: "Bachelor's in Information Technology"
    },
      {
        keywords: ['profile','introduce yourself', 'tell me about you', 'who are you', 'your background', 'self intro', 'personal story', 'your story', 'about me', 'self-description'],
        key: 'profile',
        response: "👋 I'm Elvis Otieno, a dedicated Data Architect and Cybersecurity enthusiast from Nairobi, Kenya. My career is a testament to my passion for leveraging technology to address real-world challenges, particularly in making digital spaces safer."
      },
      {
        keywords: ['current job', 'what do you do', 'role description', 'daily responsibilities', 'your profession', 'work life', 'career path', 'professional life', 'day in the life'],
        key: 'experience',
        response: "🛠️ As a Data Architect / Contractor with DataSeal Kenya, my day-to-day involves intricate data modeling, comprehensive business data analysis, and the design of robust OLTP and OLAP systems. I'm also deeply invested in enhancing cybersecurity."
      },
      {
        keywords: ['skill','skills','technical skills', 'coding abilities', 'programming expertise', 'software knowledge', 'tech stack', 'engineering skills', 'development capabilities', 'coding languages', 'technology proficiency'],
        key: 'skills',
        response: "🔧 Proficient in JavaScript and its frameworks such as React, I excel in mobile app development, Apache Kafka, AWS services, and Snowflake data warehousing. My skills span across both development and architecture, aiming to bridge gaps in cybersecurity."
      },
      {
        keywords: ['background','education','cert','school','Learn','academic history', 'certifications', 'degree information', 'schooling', 'learning achievements', 'educational journey', 'academic achievements', 'study background', 'formal education'],
        key: 'education',
        response: "🎓 My academic journey is anchored by an AWS Cloud Practitioner Certification and a Certified Information Security Manager (CISM) certification. My foundational knowledge in cloud computing and information security management fuels my expertise."
      },
      {
        keywords: ['project highlights', 'development work', 'professional projects', 'software creations', 'app development', 'technology solutions', 'work portfolio', 'creative works', 'tech projects'],
        key: 'projects',
        response: "📁 I take pride in my projects like the Kecirt mobile app for the Communication Authority of Kenya and the Truewater app. These initiatives underscore my ability to lead and execute comprehensive tech solutions."
      },
      {
        keywords: ['cybersecurity insights', 'security advocacy', 'data protection', 'hack prevention', 'security conferences', 'cyber defense', 'online safety', 'digital protection efforts', 'security community involvement'],
        key: 'cyberSecurity',
        response: "🔒 My passion for cybersecurity drives me to contribute to a safer digital environment. I regularly engage in speaking engagements, like Hexcon2022, and produce content to educate on cyber threats and defenses."
      },
      {
        keywords: ['Old','Born','Age','life experience', 'age query', 'years alive', 'time on earth', 'maturity level', 'professional tenure', 'career duration', 'experience span', 'life journey'],
        key: 'age',
        response: "🎂 In the grand scheme of things, age is indeed just a number. It's the milestones, achievements, and experiences that truly define our essence and contributions."
      },
      {
        keywords: ['favorite sports', 'athletic pursuits', 'sports engagement', 'team games', 'individual sports', 'sporting passion', 'active lifestyle', 'athletic hobbies', 'sports interests'],
        key: 'sports',
        response: "🏏 My sporting interests are diverse, yet cricket stands out for its strategic depth and the camaraderie it fosters. It's a game that beautifully combines physical skill with mental strategy."
      },
      {
        keywords: ['personal hobbies','hobbies','hobby', 'leisure time', 'free time activities', 'creative outlets', 'interest areas', 'personal pastimes', 'relaxation activities', 'fun pursuits', 'hobby interests'],
        key: 'hobbies',
        response: "📚 Beyond the digital realm, I immerse myself in the world of books. Reading is not just a pastime; it's a gateway to learning, understanding different perspectives, and sparking creativity."
      },
      {
        keywords: ['initial vehicle', 'first car memories', 'early driving days', 'automotive beginnings', 'first ride', 'vehicle history', 'driving debut', 'early automobiles', 'starter car'],
        key: 'firstCar',
        response: "🚗 My journey with cars began with a modest, dependable vehicle that was more than just a means of transport; it was a companion on many memorable journeys, embodying reliability and cherished memories."
      },
      {
        keywords: ['','travel favorites', 'exploration destinations', 'adventure locations', 'must-visit places', 'journey experiences', 'travel goals', 'dream trips', 'wanderlust wishes'],
        key: 'touringDestination',
        response: "🌍 The majestic tranquility of the mountains calls to me, with the Himalayas standing out as a pinnacle of natural beauty and serenity. Trekking these ranges was an unparalleled experience."
      },
      {
        keywords: ['vacation','vacation stories', 'recent travels', 'latest holiday', 'newest trip accounts', 'fresh travel tales', 'latest journey', 'new vacation experiences', 'recent adventure narratives', 'latest travel escapades'],
        key: 'lastVacation',
        response: "🏖️ My latest escape to the Maldives offered a much-needed retreat into serenity. The island's pristine beaches and tranquil waters provided a perfect backdrop for rejuvenation."
      },
      {
        keywords: ['self-growth', 'confidence enhancement', 'personal progress', 'development goals', 'self-betterment aims', 'improvement plans', 'growth ambitions', 'confidence projects', 'personal elevation strategies'],
        key: 'confidenceGoals',
        response: "💪 Facing challenges head-on and embracing continuous learning have been pivotal in my journey of self-improvement. Each conquered challenge fuels my confidence and growth."
      },
      {
          keywords: ['cybersecurity advocacy', 'security awareness', 'hackathons', 'cybersecurity talks', 'empowering with cybersecurity', 'cybersecurity seminars', 'security education'],
          key: 'cybersecurityAdvocacy',
          response: "🛡️ As a Cybersecurity Advocate with the Kenya Cyber Security and Forensics Association, I organize hackathons and deliver seminars to educate on cybersecurity, aiming to empower and raise awareness among both technical and non-technical audiences."
        },
        {
          keywords: ['database administration', 'DBA role', 'database management', 'database performance', 'database security', 'data recovery', 'database monitoring'],
          key: 'databaseAdministration',
          response: "🗄️ At Future Kenya LTD, I served as a Senior Database Administrator, where I managed and monitored database systems to ensure optimal performance, availability, and security, including designing, implementing, and maintaining database structures."
        },
        {
          keywords: ['web development leadership', 'architecture design', 'technology decisions', 'coding standards', 'technical leadership', 'complex issue resolution', 'team collaboration'],
          key: 'webDevelopmentLeadership',
          response: "🌐 As a Senior Web Application Developer at Secunets Technologies, I led the architectural design of web applications, enforced coding standards, and provided mentorship to junior developers, showcasing my technical leadership and expertise."
        },
        {
          keywords: ['cloud computing certification', 'AWS certification', 'cloud practitioner', 'AWS services', 'cloud security', 'cloud management', 'AWS cloud'],
          key: 'cloudComputingCertification',
          response: "☁️ I hold an AWS Cloud Practitioner Certification, demonstrating my fundamental understanding of cloud computing concepts, AWS services, cloud security, and best practices for secure cloud solutions."
        },
        {
          keywords: ['information security management', 'CISM certification', 'risk management', 'security governance', 'incident response', 'security policies', 'ISACA certification'],
          key: 'informationSecurityManagement',
          response: "🔐 Achieving the Certified Information Security Manager (CISM) certification from ISACA, I've validated my expertise in information security management, governance, and risk management, emphasizing my commitment to safeguarding digital assets."
        },
        {
          keywords: ['data warehousing training', 'Snowflake certification', 'data storage optimization', 'data querying', 'Snowflake architecture', 'data management'],
          key: 'dataWarehousingTraining',
          response: "📊 Completing Snowflake's Hands-On Essentials in Data Warehousing and Data Application Builder programs, I've gained expertise in utilizing Snowflake for data warehousing solutions, emphasizing efficient data management and analytics."
        },
        {
          keywords: ['IT education', 'technology studies','university', 'Jomo Kenyatta university', 'IT principles', 'software development education', 'network fundamentals', 'system administration'],
          key: 'itEducation',
          response: "💻 My formal education in Information Technology from Jomo Kenyatta University of Agriculture and Technology provided me with a solid foundation in IT principles, software development, network fundamentals, and system administration."
        },
        {
          keywords: ['experience with JavaScript', 'experience with React', 'experience with React Native', 'JavaScript',
          'React','experience with Node js',
          'React Native', 'software development education', 'network fundamentals', 'system administration'],
          key: 'JavaScript',
          response: " I have extensive experience working with various tools, especially in developing user interfaces using JavaScript,React,Node js and React Native frameworks. I have been involved in building both web and mobile applications, leveraging the capabilities offered by these frameworks to create efficient and responsive UIs."
        },

        {
          keywords: ['security of websites', 'security of mobile applications', 'Security', 'Cyber Security'],
          key: 'Cyber Security',
          response: "I have worked on Cyber Security related projects and i think it is paramount in both website and mobile application development. I prioritize security by implementing best practices such as encryption, secure authentication methods, input validation, and regular security audits. Additionally, I stay updated with the latest security trends and vulnerabilities to proactively address any potential threats."
        },

        {
          keywords: ['Apache Kafka', 'Kafka', 'projects where you utilized Apache Kafka'],
          key: 'Kafka',
          response: "Absolutely. I have worked on projects where Apache Kafka was employed as a distributed streaming platform. For instance, I utilized Kafka for real-time data processing and event-driven architectures, ensuring seamless communication between different components of the system while maintaining scalability and fault tolerance."
        },
        {
          keywords: ["Snowflake Certified", "Snowflake", "Snowflake Hands-On Essentials", "data warehousing platform", "data warehousing solutions"],
          key: "Snowflake",
          response: "My Snowflake certifications demonstrate my expertise in designing and managing data warehousing solutions using Snowflake's cloud-based platform. I am proficient in data modeling, ETL processes, and building data applications within Snowflake. This proficiency enables me to architect robust and scalable data solutions that meet the diverse needs of businesses and stakeholders."
        },
        
        {
          keywords: ["AWS services", "AWS Cloud Practitioner", "AWS Management Console", "Amazon EC2", "Amazon S3", "Amazon RDS"],
          key: "AWS",
          response: "In my role, I have extensively utilized various AWS services such as Amazon S3 for scalable storage, Amazon EC2 for flexible virtual server provisioning, and Amazon RDS for managed relational databases. These services have enabled me to architect and deploy robust and scalable cloud-based solutions for diverse business requirements."
        },
        
        {
          keywords: ["Cybersecurity Advocate", "Kenya Cyber Security and Forensics Association", "cybersecurity awareness", "hackathons", "cybersecurity seminars"],
          key: "Cybersecurity Advocate",
          response: "As a Cybersecurity Advocate, I organize hackathons, deliver talks, and conduct seminars to raise awareness about cybersecurity among individuals, particularly in universities. My goal is to empower both technical and non-technical audiences with practical knowledge and tips to secure their digital environments effectively."
        },
        
        {
          keywords: ["database performance tuning", "query optimization", "database troubleshooting", "database performance issues"],
          key: "Database Performance",
          response: "Database performance tuning is critical for ensuring optimal system performance and efficiency. I adopt a proactive approach by analyzing query execution plans, identifying bottlenecks, and optimizing indexes and database configurations. Additionally, I leverage tools like Explain, Trace, and Tkprof to diagnose and resolve performance issues effectively."
        },
        
        {
          keywords: ["DevOps practices", "DevOps tools", "database management", "integration with DevOps pipelines"],
          key: "DevOps",
          response: "DevOps practices and tools play a crucial role in streamlining software development and deployment processes. In my role, I collaborate with DevOps teams to integrate database management tasks seamlessly into the overall development and deployment pipelines. This includes automating database provisioning, configuration management, and deployment tasks using tools such as Git, Jenkins, and Terraform."
        },
        
        {
          keywords: ["mentoring junior team members", "skill development", "team collaboration", "teamwork"],
          key: "Mentorship",
          response: "Mentoring and skill development are integral parts of fostering a collaborative and high-performing team culture. I provide guidance and support to junior team members by sharing my knowledge and experience, assigning them challenging tasks, and providing constructive feedback on their work. Additionally, I encourage continuous learning through training opportunities, workshops, and knowledge-sharing sessions within the team."
        },
        
        {
          keywords: ["API management", "API security", "API design", "RESTful APIs"],
          key: "API Management",
          response: "API management and security are essential components of modern application development. I have experience in designing, implementing, and securing APIs to facilitate seamless communication between different systems and applications. This includes implementing authentication mechanisms, authorization policies, rate limiting, and monitoring to ensure the security and reliability of APIs."
        },
        
        {
          keywords: ["data modeling", "database design", "data optimization", "ETL processes"],
          key: "Data Modeling",
          response: "Database design and optimization require a thorough understanding of both transactional and analytical processing requirements. I utilize techniques such as normalization for OLTP systems to ensure data integrity and efficient transaction processing. For OLAP systems, I employ dimensional modeling techniques such as star and snowflake schemas to optimize data retrieval and analysis performance."
        },
        
        {
          keywords: ["compliance", "regulatory requirements", "data privacy", "data security"],
          key: "Compliance",
          response: "Compliance with regulatory requirements is essential, especially in industries with stringent data privacy and security regulations. I ensure compliance by implementing appropriate access controls, encryption mechanisms, and auditing processes to protect sensitive data. Additionally, I stay updated with relevant regulatory frameworks such as GDPR and HIPAA and incorporate necessary measures to ensure adherence to these requirements."
        },
        
        {
          keywords: ["troubleshooting", "production incidents", "issue resolution", "incident management"],
          key: "Troubleshooting",
          response: "Troubleshooting database-related issues requires a systematic approach and a deep understanding of database internals. I begin by gathering relevant information such as error logs, performance metrics, and user reports to identify the root cause of the issue. I then analyze the data, perform diagnostic tests, and implement targeted solutions to resolve the issue promptly while minimizing downtime and impact on users."
        },
        {
          keywords: ['relevant experience', 'experience', 'work experience'],
          key: 'experience',
          response: "💼 My relevant experience includes working as a Data Architect / Contractor at DataSeal Kenya, Nairobi, focusing on data modeling, business analysis, and designing OLTP and OLAP systems. I've also contributed to cybersecurity initiatives, emphasizing the importance of safeguarding digital assets.",
        },
        {
          keywords: ['strengths', 'weaknesses'],
          key: 'strengthsWeaknesses',
          response: "💪 Strengths: I excel in JavaScript development, mobile app development, and Apache Kafka utilization. Weaknesses: I tend to be overly meticulous at times, ensuring every detail is perfect, which can lead to longer timelines on projects.",
        },
        {
          keywords: ['stress', 'pressure', 'problem'],
          key: 'stressPressure',
          response: "🧘‍♂️ When faced with stress and pressure, I prioritize tasks, break them down into manageable chunks, and maintain open communication with my team. I've found that taking short breaks and practicing mindfulness techniques helps me stay focused and productive.",
        },
        {
          keywords: ['where do you see yourself'],
          key: 'future',
          response: "🔮 In the future, I envision myself continuing to innovate in the field of technology, leading impactful projects, and contributing to the cybersecurity community. I aspire to take on more leadership roles and mentor the next generation of tech professionals.",
        },
        {
          keywords: ['team'],
          key: 'team',
          response: "🤝 I thrive in a collaborative team environment, valuing diverse perspectives and leveraging each team member's strengths to achieve common goals. I actively listen, communicate effectively, and foster a supportive atmosphere where everyone feels valued and motivated.",
        },
        {
          keywords: ['motivates'],
          key: 'motivation',
          response: "🚀 What motivates me is the opportunity to tackle new challenges, innovate, and make a positive impact through my work. I'm driven by the desire to continuously learn and grow, pushing boundaries to achieve excellence.",
        },
        {
          keywords: ['leadership skills', 'leadership'],
          key: 'leadershipSkills',
          response: "👨‍💼 My leadership skills are anchored in effective communication, strategic decision-making, and leading by example. I empower team members, provide guidance and support, and foster a culture of collaboration and continuous improvement.",
        },
        {
          keywords: ['criticism'],
          key: 'criticism',
          response: "🔍 I view criticism as constructive feedback for growth and improvement. I welcome feedback with an open mind, reflect on it objectively, and use it as an opportunity to enhance my skills and performance.",
        },
        {
          keywords: ['funny'],
          key: 'funny',
          response: "😄 Well, if you're asking for a funny response, let me tell you a joke: Why don't scientists trust atoms? Because they make up everything!",
        },
        {
          keywords: ['successful project', 'project'],
          key: 'successfulProject',
          response: "🚀 One of the successful projects I've worked on was at DataSeal Kenya, where I played a pivotal role as a Data Architect / Contractor. We were tasked with revamping the data architecture for a major client in the financial sector. By implementing a robust OLTP and OLAP system, optimizing data models, and enhancing data security measures, we significantly improved their data processing efficiency and decision-making capabilities. The project not only met but exceeded the client's expectations, resulting in increased data accuracy, faster insights delivery, and ultimately, better business outcomes."
        },
        {
          keywords: ['stay updated', 'latest technology','current in your field','latest in your field'],
          key: 'stayUpdatedWithLatestTech',
          response: "📚 Staying updated with the latest technology is essential in the fast-paced IT industry. To ensure I'm always at the forefront of innovation, I adopt a multi-faceted approach. Firstly, I'm an avid reader of tech blogs, forums, and industry publications such as TechCrunch, Hacker News, and Reddit's technology section. These platforms provide invaluable insights into emerging trends, new tools, and best practices. Additionally, I actively participate in online communities and attend tech conferences, workshops, and webinars whenever possible. Platforms like LinkedIn and GitHub help me connect with fellow professionals and stay abreast of their projects and contributions. Furthermore, I regularly enroll in online courses and certifications, leveraging platforms like Coursera, Udemy, and LinkedIn Learning to deepen my expertise in specific areas of interest. Lastly, I believe in hands-on experimentation and exploration. Setting up personal projects, experimenting with new technologies, and contributing to open-source projects not only keeps me engaged but also hones my skills and fosters continuous learning. By combining these strategies, I ensure that I remain well-informed and adaptable in the ever-evolving landscape of technology."
        },
        {
          keywords: ['handle conflicts', 'coworkers'],
          key: 'handleConflictsWithCoworkers',
          response: "⚖️ Handling conflicts with coworkers requires a blend of empathy, communication, and problem-solving skills. Firstly, I strive to approach conflicts with an open mind and a willingness to understand the perspectives of all parties involved. Active listening plays a crucial role, allowing me to acknowledge concerns and validate emotions. Once I've gained insight into the root causes of the conflict, I aim to facilitate constructive dialogue. This involves communicating my own thoughts and feelings clearly and respectfully, while also encouraging others to do the same. Collaboratively identifying common ground and exploring potential solutions often leads to mutually beneficial resolutions. However, if a resolution cannot be reached immediately, I'm not afraid to seek guidance from a supervisor or mediator to help navigate the situation impartially. Regardless of the outcome, I prioritize maintaining professionalism and fostering positive working relationships moving forward. Conflict, when managed effectively, can serve as an opportunity for growth and strengthened teamwork."
        },
        {
          keywords: ['leadership skills'],
          key: 'leadershipSkillsExample',
          response: "🚀 One memorable instance showcasing my leadership skills occurred during a complex project at [insert company name]. As the team lead, I was tasked with coordinating a diverse group of individuals, each with unique expertise and perspectives. To ensure cohesion and alignment, I began by establishing clear objectives and outlining roles and responsibilities for each team member. I encouraged open communication channels, fostering an environment where everyone felt empowered to share ideas and raise concerns. Recognizing the importance of collaboration, I facilitated regular team meetings to assess progress, address challenges, and refine strategies as needed. Additionally, I actively promoted a culture of continuous learning and skill development, organizing workshops and training sessions to enhance team members' capabilities. When obstacles arose, I remained calm and composed, offering guidance and support to keep morale high and motivation intact. By leading by example and demonstrating a strong work ethic, I inspired my team to surpass expectations and achieve outstanding results. Ultimately, our successful project completion not only met the client's expectations but also strengthened the bonds within the team, showcasing the effectiveness of my leadership approach."
        },
        {
          keywords: ['where do you see yourself', 'years'],
          key: 'futurePlans',
          response: "🔮 I envision myself in a position where I have expanded my expertise and taken on greater responsibilities in the field of [insert relevant industry or role]. Building upon my current skills and experiences, I aim to become a recognized leader in [specific area or domain] and contribute meaningfully to innovative projects that drive positive change. I see myself taking on more strategic roles, possibly in [mention any areas of interest such as leadership, research, or entrepreneurship], where I can leverage my strengths in [highlight key strengths or competencies]. Additionally, I am committed to ongoing learning and professional development, staying abreast of emerging technologies and industry trends to remain at the forefront of my field. Ultimately, I strive to make a lasting impact, both within my organization and in the broader community, by tackling complex challenges and inspiring others to reach their full potential."
        },
        {
          keywords: ['LinkedIn account','LinkedIn profile'],
          key: 'linkedinAccount',
          response: "Yes, I do have a LinkedIn account. You can find me on LinkedIn at `https://www.linkedin.com/in/elvis-otieno-2018/`. I actively use LinkedIn to network with professionals in my field, stay updated on industry trends, share insights, and explore career opportunities. Feel free to connect with me there!"
        }
        ,
        {
          keywords: ['facebook','youtube','twitter'],
          key: 'facebook',
          response: "Yes, I do have them but i prefer to handout my LinkedIn account. You can find me on LinkedIn at `https://www.linkedin.com/in/elvis-otieno-2018/`. I actively use LinkedIn to network with professionals in my field, stay updated on industry trends, share insights, and explore career opportunities. Feel free to connect with me there!"
        },
        {
          keywords: ['like what','example'],
          key: 'like what',
          response: "Could you please provide more context or rephrase your question? I want to make sure I understand what you're asking so I can provide the most relevant response. Example what is your name? What are your skills? Something close to that"
        }
   
    ];

const ElvisPortfolio = () => {
    const [command, setCommand] = useState('');
    const [output, setOutput] = useState('');
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [link, setLink] = useState('');
    const [loading, setLoading] = useState(false);
    const [context, setContext] = useState(null); // Correctly defined state for context
    const [history, setHistory] = useState([]);
    const [isTyping, setIsTyping] = useState(false);
    const [typedResponse, setTypedResponse] = useState('');
    const [typingContent, setTypingContent] = useState('');


    const getCVResponse = (question) => {
      const doc = nlp(question.toLowerCase());
    
     
        
  
  
  let responseKey = mappings.find(mapping => mapping.keywords.some(keyword => doc.has(keyword)))?.key || 'profile';
  let response = cvData[responseKey] || mappings.find(mapping => mapping.key === responseKey)?.response;
  
  
    const links = {
      skills: "https://www.linkedin.com/in/elvis-otieno-2018/",
      experience: "https://www.linkedin.com/in/elvis-otieno-2018/",
      education: "https://www.linkedin.com/in/elvis-otieno-2018/",
      projects: "https://github.com/elvisDemo",
      cyberSecurity: "https://www.youtube.com/watch?v=XF4-FldNasY&t=145s",
      profile: "https://www.linkedin.com/in/elvis-otieno-2018/",
    };
    let link = links[responseKey] || ""; // Adjust to ensure a link is provided if available
  
    const foundMapping = mappings.find(mapping => mapping.keywords.some(keyword => doc.has(keyword)));
    if (foundMapping) {
      setContext(foundMapping.key); // Update the context
      return { response: foundMapping.response, link: "" }; // Assume some mappings might not have a direct link
    } else {
      // Provide creative fallback responses
      const fallbackResponses = [
        "🤔 Hmm, I might need to think about that one. Can you ask something else?",
        "🚀 I'm always eager to learn new things, but I don't have an answer to that yet!",
        "🌟 Curiosity is the engine of achievement. Let's explore other questions!",
        "🎈 That's an interesting question. I'll need to ponder on that.",
        "🌈 Let's paint a broader stroke and try a different question.",
      ];
      const randomResponse = fallbackResponses[Math.floor(Math.random() * fallbackResponses.length)];
      return { response: randomResponse, link: links[responseKey] };
    }
  
  };
  


  const handleQuestionChange = (e) => {
    setQuestion(e.target.value);
  };
  const getFollowUpResponse = (question) => {
    if (!context) return null; // No context available
  
    // Example for handling a follow-up question about the first project
    if (context === 'projects' && question.includes('first one')) {
        const firstProject = cvData.projects.split('.')[0]; // Simplistic approach: assumes projects are separated by periods
        return { response: firstProject, link: "" }; // Provide a more detailed response or link
    }
  
    // Add more conditions as needed for different contexts
    return null; // Return null or some default response if the follow-up question cannot be handled
  };
  const handleExecuteCommand = () => {
    setLoading(true);
    setTimeout(() => {  // Simulate async operation like fetching data
      const response = executeCommand(command);
      setOutput(response);
      setLoading(false);
      setCommand(''); // Clear the input after executing
    }, 500);
  };
  const executeCommand = (command) => {
    // You can implement your command execution logic here
    // For simplicity, let's just echo the command
    return `$ ${command}`;
  };
  const handleAskQuestion = () => {
    setLoading(true);
    setTimeout(() => { // Simulate async operation
        let followUpResponse = getFollowUpResponse(question.toLowerCase());

        if (followUpResponse) {
            setAnswer(followUpResponse.response);
            setLink(followUpResponse.link);
        } else {
            const { response, link } = getCVResponse(question);
            setAnswer(response);
            setLink(link);
        }

        setLoading(false);
        setQuestion(''); // Clear the input
    }, 500);
};

const clearContext = () => {
  setContext(null);
};

React.useEffect(() => {
  if (isTyping && history.length > 0) {
    const lastHistoryItem = history[history.length - 1];
    const { command, output } = lastHistoryItem;

    if (typingContent !== output) {
      const timeoutId = setTimeout(() => {
        setTypingContent(output.substring(0, typingContent.length + 1));
      }, 50); // Speed of typing

      return () => clearTimeout(timeoutId);
    } else {
      setIsTyping(false);
    }
  }
}, [isTyping, typingContent, history]);



  const getResponse = (cmd) => {
    const doc = nlp(cmd.toLowerCase());
    const foundMapping = mappings.find((mapping) =>
      mapping.keywords.some((keyword) => doc.has(keyword))
    );
    return foundMapping ? foundMapping.response : "Sorry, I can't help with that.";
  };


      // Provide creative fallback responses
      const fallbackResponses = [
        "I cannot answer that🤔 Hmm, I might need to think about that one. Can you ask something else?",
        "I cannot answer that🚀 I'm always eager to learn new things, but I don't have an answer to that yet!",
        "Is your questioned spelled out correctly🤔, Curiosity is the engine of achievement. Let's explore other questions!",
        "🎈 That's an interesting question and i cannot answer that try another.",
        "🌈 Let's paint a broader stroke and try a different question.",
      ];
      const randomResponse = fallbackResponses[Math.floor(Math.random() * fallbackResponses.length)];

const processCommand = (cmd) => {
  setIsTyping(true); // Set typing to true when processing command
  const doc = nlp(cmd.toLowerCase());
  const foundMapping = mappings.find(mapping => mapping.keywords.some(keyword => doc.has(keyword)));
  let response = foundMapping ? foundMapping.response : randomResponse;
  
  // Simulate command processing delay
  setTimeout(() => {
    setHistory([...history, {
      command: `elvis:~$ ${cmd}`,
      commandPrefix: '->',
      output: response
    }]);
    setTypingContent(response); // Set typing content to response
    setIsTyping(false); // Set typing to false after processing
  }, 1000); // Adjust delay as necessary
};

  
  // Simulate command processing delay


  const handleEnterPress = (e) => {
    if (e.key === 'Enter' && command.trim() !== '') {
      processCommand(command);
      setCommand('');
    }
  };

return (
<Container style={{ marginTop: '30px' }} className="elvisPortfolioBackground">

<Segment inverted style={{ fontFamily: "'JetBrains Mono', monospace" }}>
                    <p>Type and ask a question about my (profile, my skills, my experience, my education,my projects,my cyberSecurity/React/React Native career) No good/Correct grammer no answer for you </p>
          
                </Segment>
  <Segment inverted style={{ minHeight: '300px', backgroundColor: '#1e1e1e', color: 'lightgreen', fontFamily: 'monospace', overflowY: 'auto', textAlign: 'left' }}>
   
{history.map((item, index) => (
  <div key={index} style={{ marginBottom: '10px' }}>
    {/* Flex container for prefix and command */}
    <div style={{ display: 'flex', alignItems: 'center', color: 'red', fontFamily: "'JetBrains Mono', monospace" }}>
      {/* Prefix with its style */}
      <div style={{ color: '#52afc8',marginRight:'3px' }}>
        {item.commandPrefix}  
      </div>
      {/* Command text */}
      <div>
        {item.command}
      </div>
    </div>
    {/* Conditionally display the output or typing effect */}
    {index === history.length - 1 && isTyping ? (
      <Typing speed={30} text={[item.output]} />
    ) : (
      <div style={{ fontFamily: "'JetBrains Mono', monospace" }}>
        {item.output}

      </div>
    )}
  </div>
))}


    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <div style={{ color: "#52afc8" }}>elvisotieno:~$</div>
      <input
        // placeholder="Ask Something about profile, skills, experience, education, projects, cyberSecurity..."
        value={command}
        onChange={(e) => setCommand(e.target.value)}
        onKeyPress={handleEnterPress}
        style={{
          background: '#161616', // Make background transparent
          border: 'none', // Remove border
          outline: 'none', // Remove outline
          color: 'white', // Text color
          fontFamily: 'monospace', // Match the terminal's font family
          width: '100%', // Full width to allow long commands
          marginLeft: '4px', // Space between prompt and input
          caretColor: 'white', // Color of the caret
        }}
      />
    </div>
  </Segment>
  
</Container>


);
};

export default ElvisPortfolio;

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import 'semantic-ui-css/semantic.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Components/Home';
import Gtp from './Components/Gpt'
import Media from './Components/Media'
import About from './Components/About'
import { Sticky } from 'semantic-ui-react';
import Docs from './Components/Docs'
import AWS from './Components/AWS'
import SnowflakePage from './Components/Snowflake';
import Contact from './Components/Contact'
import './App.css'
function App() {
  return (

      <div className="App">

        <div>
        <Router>
          <Container fluid>
            <Row>
            <Col md={8} style={{ paddingLeft: 0 }}>
                <div style={{ paddingLeft: '1px' }}>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/About" element={<About />} />
                    <Route path="/Media" element={<Media />} />
                    <Route path="/Contact" element={<Contact />} />
                    <Route path="/Docs" element={<Docs />} />
                    <Route path="/AWS" element={<AWS />} />
                    <Route path="/Snowflake" element={<SnowflakePage />} />

                  </Routes>
                </div>
              </Col>
              <Col md={4} style={{ paddingRight: 0 }} >
              <Sticky offset={1} pushing>
              <Gtp />
              </Sticky>
              </Col>
            
            </Row>
          </Container>
          </Router>
        </div>
  
      </div>

  );
}

export default App;

import React from 'react';
import { Container, Segment, Header, List } from 'semantic-ui-react';
import NavItem from './NavItem';

const Doc = () => {
    return (
        <div style={{ backgroundColor: '#24292e', minHeight: '100vh', fontFamily: 'Courier New, monospace', color: '#ffffff' }}>
            <NavItem/>

            <Container text style={{ marginTop: '2em' }}>
                <Segment inverted>
                    <Header as='h2'>Google Dorks: Enhancing Web Search</Header>
                    <p>Google Dorks leverage advanced search parameters to find very specific information on Google's vast index of websites. Here are ten of the most powerful and popular Google Dork operators:</p>
                    
                    <List ordered>
                        <List.Item><strong>site:</strong> - Narrows results to a specific website. Example: <code>site:example.com</code></List.Item>
                        <List.Item><strong>filetype:</strong> - Searches for specific file types. Example: <code>filetype:pdf</code></List.Item>
                        <List.Item><strong>intitle:</strong> - Finds pages with specific words in the title. Example: <code>intitle:"security report"</code></List.Item>
                        <List.Item><strong>inurl:</strong> - Searches for a URL containing a specific keyword. Example: <code>inurl:admin</code></List.Item>
                        <List.Item><strong>intext:</strong> - Looks for pages containing the text. Example: <code>intext:"confidential document"</code></List.Item>
                        <List.Item><strong>cache:</strong> - Shows the cached version of a site. Example: <code>cache:securitytrails.com</code></List.Item>
                        <List.Item><strong>allintitle:</strong> - Similar to intitle, but requires all words in the title. Example: <code>allintitle:"index of" passwords</code></List.Item>
                        <List.Item><strong>allintext:</strong> - Searches for pages where all specified words appear in the text. Example: <code>allintext:username filetype:log</code></List.Item>
                        <List.Item><strong>allinurl:</strong> - Similar to inurl, but for multiple words in the URL. Example: <code>allinurl:clientarea</code></List.Item>
                        <List.Item><strong>inanchor:</strong> - For finding specific text within an anchor text. Example: <code>inanchor:"cyber security"</code></List.Item>
                    </List>

                    {/* <p>Google Dorks can be incredibly powerful for researchers, journalists, or anyone trying to find specific data online. However, it's important to use these tools ethically and responsibly.</p> */}
                </Segment>
            </Container>
        </div>
    );
};

export default Doc;

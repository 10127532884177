import React, { useState, useEffect } from 'react';
import { Container, Segment, Loader, Header, List, Button, Image } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import NavItem from './NavItem'; // Ensure NavItem is correctly imported

const AWSPage = () => {
    // State to manage loading
    const [isLoading, setIsLoading] = useState(true);

    // Simulate data loading
    useEffect(() => {
        // Simulate a fetch call with setTimeout
        setTimeout(() => setIsLoading(false), 2000); // Hide loader and show content after 2 seconds
    }, []);

    return (
        <div style={{ backgroundColor: '#24292e', minHeight: '100vh', fontFamily: 'Courier New, monospace', color: '#ffffff' }}>
            <NavItem/>

            {isLoading ? (
                <Loader active inline="centered" style={{ marginTop: '2em' }}>Loading...</Loader>
            ) : (
                <Container text style={{ marginTop: '2em' }}>
                    <Segment inverted>
                        <Header as='h2'>AWS Skills and Projects</Header>
                        <p>Demonstrating a deep expertise in AWS technologies, my journey includes achieving AWS Cloud Practitioner certification and implementing complex projects such as building a cryptocurrency data pipeline from data ingestion to analytics.</p>
                        
                        <Header as='h3'>Certifications</Header>
                        <List>
                            <List.Item>AWS Cloud Practitioner</List.Item>
                            <List.Item>AWS Solutions Architect (Inprogress ...)</List.Item>
                        </List>

                        <Header as='h3'>Key AWS Projects</Header>
                        <List>
                            <List.Item>
                                <strong>Building a Cryptocurrency Data Pipeline on AWS</strong> - From data ingestion to analytics. A detailed walkthrough of this project can be found on my <a href="https://medium.com/@otienoelvis13/building-a-cryptocurrency-data-pipeline-on-aws-from-data-ingestion-to-analytics-2ea2499b75e0" style={{ color: 'lightblue' }}>Medium post</a>.
                            </List.Item>
                            {/* Add other projects here */}
                        </List>

                        <Header as='h3'>AWS Architectures Demonstrated</Header>
                        <p>Throughout my projects, I've utilized and architected solutions leveraging AWS services such as Amazon S3, Lambda, EC2, and RDS to create scalable, efficient systems.</p>
                        {/* For demonstration purposes, you might want to include images or diagrams of your architectures. If you have them as images, you can use the Image component */}
                        {/* <Image src="path_to_your_architecture_diagram.jpg" alt="Architecture Diagram" /> */}

                        <p>My work embodies a commitment to harnessing the power of cloud technologies to drive innovation and efficiency. I am continually exploring new AWS services and architectural patterns to stay at the forefront of cloud computing.</p>
                        
                        <Button secondary onClick={() => window.open("https://www.linkedin.com/in/elvis-otieno-2018/", "_blank")}>Connect on LinkedIn</Button>
                    </Segment>
                </Container>
            )}
        </div>
    );
};

export default AWSPage;

import React from 'react';
import { Menu,Label } from 'semantic-ui-react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import {  FaAws } from 'react-icons/fa'; // Adjusted import
import { SiSnowflake } from "react-icons/si";
import { FcGoogle } from "react-icons/fc";
import { SiMediafire } from "react-icons/si";
import 'semantic-ui-css/semantic.min.css';
import { Helmet } from 'react-helmet';
import { PiContactlessPaymentFill } from "react-icons/pi";
import { FcAbout } from "react-icons/fc";
import './style.css'

// Since react-icons does not include specific AWS or Snowflake icons,
// and for demonstration, FaAws and FaSnowflakeO are used as placeholders.
// Replace these with appropriate icons or consider custom SVGs if specific logos are needed.

const Portfolio = () => {
    return (
        <div style={{ backgroundColor: '#24292e', minHeight: '10vh', fontFamily: 'Courier New, monospace', color: '#ffffff' }} className="sticky-container">
              <Helmet>
                <title>My Portfolio</title>
                <meta name="description" content="Welcome to my portfolio. Explore my work, media interviews, and more." />
                <meta name="keywords" content="portfolio, web developer, projects, media interviews,Cyber Security" />
                <meta property="og:title" content="My Portfolio" />
                <meta property="og:description" content="Discover my projects and media interviews on my portfolio." />
                <meta property="og:type" content="website" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Elvis Otieno" />
                <meta name="copyright" content="2024, Elvis Otieno" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="language" content="English" />
                <meta property="og:Elvis Otieno" content="My Portfolio" />
                <meta property="og:url" content="https://www.elvisotieno.com" />
                <meta property="og:image" content="https://www.elvisotieno.com/1710759970603.jpg" />
                <meta property="og:locale" content="en_US" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content="@Lvis254" />
                <meta name="twitter:image" content="https://www.elvisotieno.com/1710759970603.jpg" />
                <meta name="twitter:description" content="Discover my projects and media interviews on my portfolio." />
                <meta name="twitter:title" content="My Portfolio" />



                {/* Add more meta tags as needed */}
            </Helmet>
            <Menu inverted className="scrollable-menu">
            
                <Menu.Item name='home' as={Link} to='/'>
                    
                </Menu.Item>
                <Menu.Item name='About' as={Link} to='/About'>
                <FcAbout color='#f5971d' style={{marginRight:5}}/>  About Me
                </Menu.Item>
                <Menu.Item name='Media' as={Link} to='/Media'>
                <SiMediafire color='#f5971d' style={{marginRight:5}}/>  Media Interviews
                </Menu.Item>
                <Menu.Item name='Contact' as={Link} to='/Contact'>
                    <PiContactlessPaymentFill color='#f5971d' style={{marginRight:5}}/>  Contact
                </Menu.Item>
                <Menu.Item name='AWS'  as={Link} to='/AWS'>
                    <FaAws color='#f5971d' style={{marginRight:5}}/> AWS
                </Menu.Item>
                <Menu.Item name='Snowflake' as={Link} to='/Snowflake'>
                    <SiSnowflake color='#52afc8' style={{marginRight:5}}/> Snowflake
                </Menu.Item>
                <Menu.Item name='Docs' as={Link} to='/Docs'>
                    <FcGoogle  style={{marginRight:5}}/> Google Docs
                    
                </Menu.Item>

            </Menu>
        </div>
    );
};

export default Portfolio;

import React, { useState, useEffect } from 'react';
import { Container, Segment, Header, List, Button, Loader } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import NavItem from './NavItem'; // Ensure NavItem is correctly imported from your components

const MediaInterviews = () => {
    // Initialize loading state to true to show the loader initially
    const [isLoading, setIsLoading] = useState(true);

    // Simulate loading data with useEffect and setTimeout
    useEffect(() => {
        // Simulate a loading period (e.g., fetching data), here we use 2000 milliseconds (2 seconds)
        const timer = setTimeout(() => setIsLoading(false), 2000);

        // Cleanup function to clear the timer if the component unmounts before the timeout completes
        return () => clearTimeout(timer);
    }, []); // Empty dependency array means this runs once on mount

    return (
        <div style={{ backgroundColor: '#24292e', minHeight: '100vh', fontFamily: 'Courier New, monospace', color: '#ffffff' }}>
            <NavItem/>

            {isLoading ? (
                // Show loader while content is loading
                <Loader active inline="centered" style={{ marginTop: '2em' }}>Loading...</Loader>
            ) : (
                // Render the content after loading is complete
                <Container text style={{ marginTop: '2em' }}>
                    <Segment inverted>
                        <Header as='h2'>Media Presence and Speaking Engagements</Header>
                        <p>I've been fortunate to share my insights on cybersecurity and technology innovation through various media channels and speaking engagements. Here are some highlights:</p>

                        <Header as='h3'>Key Speaking Engagements</Header>
                        <List bulleted>
                            <List.Item>
                                Hexcon2022 (Key Speaker) - <a href="https://www.hexnode.com/events/hexcon22/speakers/" style={{ color: 'lightblue' }}>View Event</a>
                            </List.Item>
                            <List.Item>
                                YouTube Presentations on Cybersecurity - <a href="https://www.youtube.com/watch?v=XF4-FldNasY&t=145s" style={{ color: 'lightblue' }}>Watch Videos</a>
                            </List.Item>
                        </List>

                        <Header as='h3'>Available For</Header>
                        <List bulleted>
                            <List.Item>Podcasts and Webinars</List.Item>
                            <List.Item>Panel Discussions</List.Item>
                            <List.Item>News Articles and Blogs</List.Item>
                            <List.Item>Live Speaking Engagements</List.Item>
                        </List>

                        <p>To discuss participation in your event or media project, please reach out via email or phone.</p>

                        <Button secondary onClick={() => window.location.href='mailto:otienoelvis13@gmail.com'}>Contact for Participation</Button>

                        <div style={{ marginTop: '2em' }}>
                            <p>Phone: (+254) 799-670-496</p>
                        </div>
                    </Segment>
                </Container>
            )}
        </div>
    );
};

export default MediaInterviews;

import React, { useState, useEffect } from 'react';
import { Container, Segment, Loader, Header, List, Button } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import NavItem from './NavItem'; // Ensure NavItem is correctly imported

const SnowflakePage = () => {
    // State to manage loading
    const [isLoading, setIsLoading] = useState(true);

    // Simulate data loading
    useEffect(() => {
        // Simulate a fetch call with setTimeout
        setTimeout(() => setIsLoading(false), 2000); // Hide loader and show content after 2 seconds
    }, []);

    return (
        <div style={{ backgroundColor: '#24292e', minHeight: '100vh', fontFamily: 'Courier New, monospace', color: '#ffffff' }}>
            <NavItem/>

            {isLoading ? (
                <Loader active inline="centered" style={{ marginTop: '2em' }}>Loading...</Loader>
            ) : (
                <Container text style={{ marginTop: '2em' }}>
                    <Segment inverted>
                        <Header as='h2'>Snowflake Skills and Certifications</Header>
                        <p>With a passion for data architecture and analytics, I have acquired significant expertise in utilizing Snowflake's cloud data platform to drive insights and support data-driven decision-making.</p>
                        
                        <Header as='h3'>Certifications</Header>
                        <List>
                            <List.Item>Snowflake SnowPro Core Certification</List.Item>
                            <List.Item>Snowflake SnowPro Advanced: Data Engineer</List.Item>
                            {/* Add other Snowflake certifications here if you have more */}
                        </List>

                        <Header as='h3'>Key Snowflake Projects</Header>
                        <List>
                            <List.Item>
                                Data Warehouse Modernization - Leveraged Snowflake to streamline data processing and analytics workflows, enhancing performance and scalability.
                            </List.Item>
                            {/* Add other projects or significant work accomplishments with Snowflake here */}
                        </List>

                        <p>My work with Snowflake encompasses developing scalable data models, optimizing data storage and retrieval, and implementing robust data security practices. I am dedicated to exploring Snowflake's capabilities to enhance data warehousing and analytics strategies.</p>
                        
                        <Button secondary onClick={() => window.open("https://www.linkedin.com/in/elvis-otieno-2018/", "_blank")}>Connect on LinkedIn</Button>
                    </Segment>
                </Container>
            )}
        </div>
    );
};

export default SnowflakePage;

import React, { useState, useEffect } from 'react';
import { Container, Segment, Button, Image, Grid, Loader } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import NavItem from './NavItem';
import MyImage from '../Image/1710759970603.jpg';
import Typing from 'react-typing-effect';

const Portfolio = () => {
    const [isLoading, setIsLoading] = useState(true); // Add this line to manage loading state

    useEffect(() => {
        const timer = setTimeout(() => setIsLoading(false), 3000); // Simulate loading for 3 seconds
        return () => clearTimeout(timer); // Cleanup the timer
    }, []);

    const text = `
        Hello
        ------------------------------------
     Use the menu above to navigate. Enjoy exploring my portfolio as if you were using the Git command line.
     Commands(Type to find out)
    `;

    return (
        <div style={{ backgroundColor: '#24292e', minHeight: '100vh', fontFamily: 'Courier New, monospace', color: '#ffffff' }}>
            <NavItem/>
            {isLoading ? (
                <Loader active inline='centered' size='medium' style={{ marginTop: '10%' }}>Loading...</Loader>
            ) : (
                <Container style={{ marginTop: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid stackable centered>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Segment style={{ fontFamily: "'JetBrains Mono', monospace" }} inverted textAlign='center'>
                                    <p style={{ whiteSpace: 'pre-wrap' }}>{text}</p>
                                </Segment>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '15px' }}>
                                    <Image src={MyImage} alt='Your Name' size='medium' rounded />
                                </div>
                            </Grid.Column>
                            <Button secondary onClick={() => window.open("https://www.linkedin.com/in/elvis-otieno-2018/", "_blank")}>Connect on LinkedIn</Button>
                        </Grid.Row>
                    </Grid>
                </Container>
            )}
        </div>
    );
};

export default Portfolio;

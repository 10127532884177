import React, { useState, useEffect } from 'react';
import { Container, Segment, Loader } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import NavItem from './NavItem'; // Ensure NavItem is correctly imported from your components

const Contact = () => {
    // State to manage loading
    const [isLoading, setIsLoading] = useState(true);

    // Simulate data loading
    useEffect(() => {
        // Simulate a fetch call with setTimeout
        const timer = setTimeout(() => {
            setIsLoading(false); // Hide loader and show content after 2 seconds
        }, 2000);

        // Cleanup the timer when component unmounts
        return () => clearTimeout(timer);
    }, []);

    return (
        <div style={{ backgroundColor: '#24292e', minHeight: '100vh', fontFamily: 'Courier New, monospace', color: '#ffffff' }}>
            <NavItem/>

            {isLoading ? (
                <Loader active inline="centered" style={{ marginTop: '2em' }}>Loading...</Loader>
            ) : (
                <Container text style={{ marginTop: '2em' }}>
                    <Segment inverted>
                        <h2>Contact Me</h2>
                        <p><strong>Elvis Otieno</strong></p>
                        <p>Nairobi, Kenya</p>
                        <p>Phone: (+254) 799-670-496</p>
                        <p>Email: otienoelvis13@gmail.com</p>
                        <p>LinkedIn: <a href="https://www.linkedin.com/in/elvis-otieno-2018/" style={{ color: 'lightblue' }}>https://www.linkedin.com/in/elvis-otieno-2018/</a></p>
                        <p>GitHub: <a href="https://github.com/elvisDemo" style={{ color: 'lightblue' }}>https://github.com/elvisDemo</a></p>
                        <p>WakaTime: <a href="https://wakatime.com/@ElvisTest" style={{ color: 'lightblue' }}>https://wakatime.com/@ElvisTest</a></p>
                        <p>Hexcon2022 (Key Speaker): <a href="https://www.hexnode.com/events/hexcon22/speakers/" style={{ color: 'lightblue' }}>https://www.hexnode.com/events/hexcon22/speakers/</a></p>
                        <p>YouTube: <a href="https://www.youtube.com/watch?v=XF4-FldNasY&t=145s" style={{ color: 'lightblue' }}>https://www.youtube.com/watch?v=XF4-FldNasY&t=145s</a></p>
                    </Segment>
                </Container>
            )}
        </div>
    );
};

export default Contact;
